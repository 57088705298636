<template>
    <ONavbar></ONavbar>

    <ORowContainer appContainer>
        <h1>{{ msg }}</h1>
        <input v-model="msg">
    </ORowContainer>
</template>

<script setup>
import ONavbar from "o365.vue.components.ONavbar.vue";
import { ref } from 'vue';
// $getDataObjectById

const msg = ref('Hello Omega 365');
</script>